<template>
  <MainPanel>
    {{ this.$route.name }}
  </MainPanel>
</template>

<script>
import MainPanel from "@/components/basic/MainPanel";

export default {
  name: 'CodeGeeX',
  components: {
    MainPanel
  },
  data() {
    return {

    }
  },

  methods: {

  },

  created() {

  },

  mounted() {

  }
}
</script>
